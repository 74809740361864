import React from 'react';
import {firebase} from '../base'
//import auth from '../base';

class LoginPage extends React.Component {
    emailInput = React.createRef();
    passInput = React.createRef();
    
    checkLogin = (event) => {
        event.preventDefault();
        let email = this.emailInput.current.value;
        let pass = this.passInput.current.value;
        firebase.auth().signInWithEmailAndPassword(email, pass)
            .then((userCredential) => {
            // Signed in
            var user = userCredential.user;
            this.navigate(user.providerData[0].displayName);
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    }
    goToAccountCreator = (event) => {
        this.navigate('createAccount')
    }
    navigate = (valUser) => {
        this.props.history.push(`/${valUser}`);
    }
    render() {
        return (
            <>
            <form className="login-page" onSubmit={this.checkLogin}>
                <h1>AHOY!</h1>
                <input type="text" required placeholder="email" ref={this.emailInput}></input>
                <input type="text" required placeholder="password" ref={this.passInput}></input>
                <button type="submit">Go to your rigging...</button>
            </form>
            <div onClick={this.goToAccountCreator}>
                <span>Create an account?</span>
            </div>
            </>
        )
    }
}

export default LoginPage;