import React from 'react';

class ScaleInput extends React.Component {
    xRef = React.createRef();
    yRef = React.createRef();
    zRef = React.createRef();
    
    state = {
        target : {
            id : "scale",
            value : {
                x : 0,
                y : 0,
                z : 0
            }               
        }
    }
    
    handleChange = (event) => {
        console.log(typeof event.target.value)
        if (event.target.value !== "") {
            console.log('number entered');
            let target = {...this.state.target};
            let value = {x : this.xRef.current.value, y: this.yRef.current.value, z: this.zRef.current.value};
            target.value = value
            this.setState({target}, ()=> {
                this.props.handleEdit(this.state);
            })
        }
    }
    
    render() {
        return (
            <div>
                <span>Scale: </span>
                <label>X: </label>
                <input id="xpos" type="text" ref={this.xRef} required value={this.state.target.value.x} onChange={this.handleChange}></input>
                <label>Y: </label>
                <input id="ypos" type="text" ref={this.yRef} required value={this.state.target.value.y} onChange={this.handleChange}></input>
                <label>Z: </label>
                <input id="zpos" type="text" ref={this.zRef} required value={this.state.target.value.z} onChange={this.handleChange}></input>
            </div>
        )
    }

    componentDidMount() {
        let target = {...this.state.target};
        let value;
        if (this.props.cue.action._cue) {
            let scale;
            scale = this.props.cue.action._cue.action.scale;
            if (scale) {
                value = {x : scale.x, y : scale.y, z : scale.z};
            } else {
                return;
            }
        } else {
            if (!this.props.cue.action.scale) return;
            value = {x : this.props.cue.action.scale.x, y : this.props.cue.action.scale.y, z : this.props.cue.action.scale.z};
        }
        target.value = value;
        this.setState({target})
    }
}

export default ScaleInput;