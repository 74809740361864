import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: "AIzaSyDhEwmJ28S6MMZdBpWHWxNDcZR0MU3W3jA",
    authDomain: "the-port-treasury.firebaseapp.com",
    databaseURL: "https://the-port-treasury-default-rtdb.firebaseio.com",
    projectId: "the-port-treasury",
    storageBucket: "the-port-treasury.appspot.com",
    messagingSenderId: "219333063617",
    appId: "1:219333063617:web:e2d386469870cc0647f540",
    measurementId: "G-9VW8WMS6DF"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const store = firebase.firestore();
const auth = firebase.auth();

export { firebase };
export { auth };

export default store;

