import React from 'react';
import ProjectHeader from "./ProjectsHeader";
import ProjectPreview from './ProjectPreview';
import ProfileHeader from './ProfileHeader';
import ChooseNewRig from './ChooseNewRig';
import store from "../base";
import ProjectCreator from './ProjectCreator';


//this should be removed in the future
import projDB from "../testprojectdb.json"

class UserPage extends React.Component {
    state = {
        projects : [],
        projectCreator: false
    };
    getProjects = () => {
        //Get all projects associated with username here
        //console.log('querying database');
        //need to work on passing data between components
        let userName = this.props.match.params.username;
        //console.log(userName);
        let db = store.collection('onboardxr-db').doc('project-db');
        db.get().then((doc) => {
            if (doc.exists) {
                let projDB = doc.data()
                let projects = projDB.listProjects.filter(x => x.contributors.includes(userName));
                //console.log(projects);
                if (projects.length > 0) {
                    this.setState({projects});
                } else {
                    console.log('cannot find any projects for this user');
                }
            } else {
                console.log('cannot find project data');
            }
        }).catch((error) => {
            console.log('error accessing database: ' + error);
        });
    }
    
    loadProjectData = () => {
        alert('loading project data');
    }
    
    parseData = (data) => {
        let map = data.listCue.filter(x => x.project === "PolysMain");
        console.log(map);
    }

    navigate = (project) => {
        this.props.history.push(`/${this.props.match.params.username}/${project}`);
    }

    createNewProject = () => {
        let projectCreator = true;
        this.setState({projectCreator});
    }

    closeProjectCreator = () => {
        let projectCreator = false;
        this.setState({projectCreator});
    }

    createProject = (projectObj) => {
        console.log(this.state.projects);
        let projects = [...this.state.projects];
        console.log(projects)
        projects.push(projectObj);
        //console.log(projectObj)
        //console.log(projects);
        this.setState({projects}, () => {
            //console.log(this.state.projects);
            let obj = {
                listProjects : this.state.projects
            }
            let db = store.collection('onboardxr-db').doc('project-db');
            db.set(obj)
            .then(() => {
                console.log("Document successfully written!");
                this.closeProjectCreator();
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });
        });
    }

    removeProjectClick = (title) => {
        //console.log('removing contrib from project: ', title);
        let projects = [...this.state.projects];
        let index = projects.findIndex(el => el.title === title);
        //console.log(projects[index].contributors)
        let contribIndex = projects[index].contributors.find(el => el === this.props.match.params.username);
        projects[index].contributors.splice(contribIndex, 1);
        console.log(projects);
        let obj = {
            listProjects : projects
        }
        let db = store.collection('onboardxr-db').doc('project-db');
        db.set(obj)
        .then(() => {
            console.log("Document successfully written!");
            projects.splice(index, 1);
            this.setState({projects});
        })
        .catch((error) => {
            console.error("Error writing document: ", error);
        });
    }

    render() {
        return (
            <div>
                <ProfileHeader username={this.props.match.params.username}/>
                <ChooseNewRig createNewProject={this.createNewProject}/>
                <div>
                    <ProjectHeader />
                    {Object.keys(this.state.projects).map(key => <ProjectPreview removeProject={this.removeProjectClick} key={key} navigate={this.navigate} projData={this.state.projects[key]}/>)}
                </div>
                {this.state.projectCreator && (
                    <ProjectCreator username={this.props.match.params.username} closeProjectCreator={this.closeProjectCreator} createNewProject={this.createProject}/>
                )}
            </div>
        )
    }
    componentDidMount() {
        this.getProjects();
        // console.log(projDB);
        // let db = store.collection('onboardxr-db').doc('project-db');
        // db.set(projDB)
        // .then(() => {
        //     console.log("Document successfully written!");
        //     // projects.splice(index, 1);
        //     // this.setState({projects});
        // })
        // .catch((error) => {
        //     console.error("Error writing document: ", error);
        // });
    }
}

export default UserPage;