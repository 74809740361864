import React from 'react';
import BuildHeader from './BuildHeader';
import CueDisplay from './CueDisplay';
import BuildFooter from './BuildFooter'
import store from '../base';
import CueEditor from "./CueEditor";
import cueDefaults from "../defaultcues.json"


class BuilderPage extends React.Component {
    state = {
        projectCues : [],
        groups : [],
        cueDB : {},
        editing : false,
        currentCue : {},
        newCue : false
    }

    createCue = () => {
        let newCue = true;
        this.setState({newCue}, ()=> {
            let defaultCue = {...cueDefaults.listDefaults[0].default};
            defaultCue.action = {...cueDefaults.listDefaults[0].default.action};
            defaultCue.target = {...cueDefaults.listDefaults[0].default.target};
            defaultCue.trig = {...cueDefaults.listDefaults[0].default.trig};
            //console.log(cueDefaults.listDefaults[0])
            //console.log(defaultCue)
            this.showPopup(defaultCue);
        });
    }

    navigate = () => {
        this.props.history.push(`/${this.props.match.params.username}/`)
    }

    onCueUpdate = (cue) => {
        //console.log(cue);
        let oldCueIndex = this.state.projectCues.findIndex(item => item.name === cue["name"]);
        //console.log(this.state.projectCues[oldCueIndex])
        let projectCues = [...this.state.projectCues]
        if (this.state.projectCues[oldCueIndex]) {
            projectCues.splice(oldCueIndex, 1, cue);
        } else {
            console.log('pushing new cue')
            projectCues.push(cue)
            // projectCues.push(cue);
        }
        this.setState({projectCues});
        this.parseGroups(projectCues);
        this.closePopup();
    }

    deleteCue = (cue) => {
        let oldCueIndex = this.state.projectCues.findIndex(item => item.name === cue["name"]);
        let projectCues = this.state.projectCues;
        projectCues.splice(oldCueIndex, 1);
        this.setState({projectCues});
        this.parseGroups(projectCues);
        this.closePopup();
    }

    saveProject = () => {
        console.log('saving project');
        //console.log(this.state.cueDB);
        let projectCues = [...this.state.projectCues];
        let projectName = this.props.match.params.project;
        for (let i = 0; i < projectCues.length; i++) {
            //console.log(projectCues)
            if (projectCues[i].project !== projectName) {
                projectCues[i].project = projectName
            }
        }
        let cueDB = {...this.state.cueDB};
        console.log(projectCues)
        for (let j = 0; j < cueDB.listCue.length; j++) {
            for (let z = 0; z < projectCues.length; z++) {
                if (cueDB.listCue[j].name === projectCues[z].name) {
                    cueDB.listCue.splice(j, 1, projectCues[z]);
                    console.log('removing duplicate');
                    projectCues.splice(z, 1);
                }
            }
        }
        if (projectCues.length < 1) {
            console.log('no new cues');
        } else {
            console.log("adding new cues", projectCues);
            cueDB.listCue.push.apply(cueDB.listCue, projectCues);
            console.log(cueDB)
        }
        this.setState({cueDB}, () => {
            let db = store.collection('onboardxr-db').doc('cue-db');
            db.set(this.state.cueDB)
            .then(() => {
                console.log("Document successfully written!");
                this.updateProjectData();
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });
        });
        //write all project cues to cueDB
    }

    updateProjectData = () => {
        let projectName = this.props.match.params.project;
        //console.log(userName);
        let db = store.collection('onboardxr-db').doc('project-db');
        db.get().then((doc) => {
            if (doc.exists) {
                let projDB = doc.data()
                let index = projDB.listProjects.findIndex(x => x.title === projectName);
                projDB.listProjects[index].lastEdit = new Date().toString();
                let projectCues = [...this.state.projectCues];
                //console.log(projectCues);
                projDB.listProjects[index].totalCues = projectCues.length;
                db.set(projDB)
                .then(() => {
                    console.log("Project info successfully written!");
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });
                //console.log(project);
            } else {
                console.log('cannot find project data');
            }
        }).catch((error) => {
            console.log('error accessing database: ' + error);
        });
    }

    showPopup = (currentCue) => {
        //console.log('popup opened');
        let editing = {...this.state.editing};
        editing = true;
        //console.log(currentCue)
        this.setState({currentCue}, ()=> {
            this.setState({editing});
        });
    }

    closePopup = () => {
        //console.log('popup closed');
        //console.log(cueDefaults.listDefaults[0].default)
        let editing = {...this.state.editing};
        editing = false;
        this.setState({editing});
        let currentCue = {...this.state.currentCue};
        currentCue = {};
        console.log(currentCue)
        this.setState({currentCue});
        if (this.state.newCue) {
            let newCue = false;
            this.setState({newCue});
        }
    }

    getCueData() {
        //Need better querying based only on projects
        let projectName = this.props.match.params.project;
        let db = store.collection('onboardxr-db').doc('cue-db');
        db.get().then((doc) => {
            if (doc.exists) {
                let cueDB = doc.data()
                this.setState({cueDB});
                console.log(cueDB)
                let projectCues = cueDB.listCue.filter(x => x.project === projectName);
                //console.log(projectCues);
                if (projectCues.length > 0) {
                    this.setState({projectCues});
                    this.parseGroups(projectCues);
                } else {
                    console.log('cannot find any cues for this project');
                }
            } else {
                console.log('cannot find project data');
            }
        }).catch((error) => {
            console.log('error accessing database: ' + error);
        });
    }

    parseGroups(projectCues) {
        let groups = [...new Set(projectCues.map(x => x.trig.groupChain))]
        this.setState({groups})
        //console.log(groups)
    }

    render() {
        //console.log('hello builder');
        return (
            <>
                <BuildHeader createCue={this.createCue} projectName={this.props.match.params.project}/>
                <CueDisplay cues={this.state.projectCues} groups={this.state.groups} showPopup={this.showPopup}/>
                <BuildFooter navigate={this.navigate} saveProject={this.saveProject}/>
                {this.state.editing && (
                    <CueEditor cue={this.state.currentCue} newCue={this.state.newCue} deleteCue={this.deleteCue} onCueUpdate={this.onCueUpdate} onClosePopup={this.closePopup}/>
                )}
            </>
        )
    }
    componentDidMount() {
        {this.getCueData()}
    }
}

export default BuilderPage;