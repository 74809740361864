import React from 'react';

class UrlInput extends React.Component {
    state = {
        defaultURL : ""
    }

    render() {
        return (
            <div>
                <span>Url: </span>
                <input id="url" type="text" required defaultValue={this.state.defaultURL} onChange={this.props.handleEdit}></input>
            </div>
        )
    }

    componentDidMount() {
        let defaultURL;
        if (this.props.cue.action._cue) {
            if (this.props.cue.action._cue.target.src) {
                defaultURL = this.props.cue.action._cue.target.src;
            } else if (this.props.cue.action._cue.action.src) {
                defaultURL = this.props.cue.action._cue.action.src;
            } else {
                defaultURL = this.props.cue.action._cue.action.link;
            }
        } else {
            defaultURL = this.props.cue.target.src;
        }
        this.setState({defaultURL})
    }
}

export default UrlInput;