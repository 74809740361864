import React from 'react';

class BuildHeader extends React.Component {
    render() {
        return (
            <>
                <h1>{this.props.projectName}</h1>
                <button onClick={this.props.createCue}>Create Cue</button>
            </>
        )
    }
}

export default BuildHeader;