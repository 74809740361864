import React from 'react';

class ProfileHeader extends React.Component {
    render() {
        return (
            <h1>Welcome {this.props.username}!</h1>
        )
    }
}

export default ProfileHeader;