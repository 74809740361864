import React from 'react';

class RotationInput extends React.Component {
    xRef = React.createRef();
    yRef = React.createRef();
    zRef = React.createRef();
    
    state = {
        target : {
            id : "rotation",
            value : {
                x : 0,
                y : 0,
                z : 0
            }               
        }
    }
    
    handleChange = (event) => {
        console.log(typeof event.target.value)
        if (event.target.value !== "") {
            console.log('number entered');
            let target = {...this.state.target};
            let value = {x : this.xRef.current.value, y: this.yRef.current.value, z: this.zRef.current.value};
            target.value = value
            this.setState({target}, ()=> {
                this.props.handleEdit(this.state);
            })
        }
    }
    
    render() {
        return (
            <div>
                <span>Rotation: </span>
                <label>X: </label>
                <input id="xpos" type="text" ref={this.xRef} required value={this.state.target.value.x} onChange={this.handleChange}></input>
                <label>Y: </label>
                <input id="ypos" type="text" ref={this.yRef} required value={this.state.target.value.y} onChange={this.handleChange}></input>
                <label>Z: </label>
                <input id="zpos" type="text" ref={this.zRef} required value={this.state.target.value.z} onChange={this.handleChange}></input>
            </div>
        )
    }

    componentDidMount() {
        let target = {...this.state.target};
        let value;
        if (this.props.cue.action._cue) {
            let rotation;
            if (this.props.cue.action._cue.action.rot) {
                rotation = this.props.cue.action._cue.action.rot;
            } else {
                rotation = this.props.cue.action._cue.action.rotation;
            }
            if (rotation) {
                value = {x : rotation.x, y : rotation.y, z : rotation.z};
            } else {
                return;
            }
        } else {
            if (!this.props.cue.action.rot) return;
            value = {x : this.props.cue.action.rot.x, y : this.props.cue.action.rot.y, z : this.props.cue.action.pos.z};
        }
        target.value = value;
        this.setState({target})
    }
}

export default RotationInput;