import React from 'react';

class CuePreview extends React.Component {
    handleDoubleClick = () => {
        this.props.showPopup(this.props.cue);
    }

    render() {
        return (
            <>
            <div className="project-preview" onDoubleClick={this.handleDoubleClick}>
                {/* <p>Cue Number</p> */}
                <span>Cue Name: {this.props.cue.name}   </span>
                <span>Trig Delay: {this.props.cue.trig.time}   </span>
                {/* <p>Scene Link : </p> */}
                <span>Role: {this.props.cue.role}   </span>
                <br/>
            </div>
            </>
        )
    }
}

export default CuePreview;