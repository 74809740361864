import React from 'react';

class MaxDistanceInput extends React.Component {
    render() {
        return (
            <div>
                <span>Max Distance: </span>
                <input id="maxdist" type="text" required defaultValue={this.props.cue.action._cue ? this.props.cue.action._cue.action.maxDist : ""} onChange={this.props.handleEdit}></input>
            </div>
        )
    }
}

export default MaxDistanceInput;