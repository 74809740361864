import React from 'react';
import CuePreview from './CuePreview';

class GroupCue extends React.Component {
    state = {
        showGroup: false
    }

    handleShowGroup = () => {
        console.log('toggle group')
        this.setState(prevState => ({
            showGroup: !prevState.showGroup
        }));
    }

    render() {
        return(
            <div>
                <div className="cue-group-display">
                    <button onClick={this.handleShowGroup}>Expand Group</button>
                    <h3>Cue Group : {this.props.groupName}</h3>
                </div>
                {this.state.showGroup && Object.keys(this.props.groupCues).map(key => <CuePreview key={key} cue={this.props.groupCues[key]} showPopup={this.props.showPopup}/>)}
            </div>

        )
    }
}

export default GroupCue;