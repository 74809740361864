import React from 'react';

class ProjectCreator extends React.Component {
    titleRef = React.createRef();
    contribRef = React.createRef();

    createProject = () => {
        //construct contributors array
        console.log(this.contribRef.current.value)
        let contribs = this.contribRef.current.value.split(', ')
        if (!contribs.includes(this.props.username)) {
            contribs.splice(0, 0, this.props.username);
        }
        //construct current date
        let date = new Date().toString();

        //construct project obj
        let projectObj = {
            title: this.titleRef.current.value,
            contributors: contribs,
            dateCreated: date,
            lastEdit: date,
            totalCues: 0
        }
        this.props.createNewProject(projectObj);
    }

    render() {
        return (
            <div className="cue-editor">
                <div className="cue-content">
                    <span>CREATE NEW PROJECT</span>
                    <div>
                        <span>PLATFORM: Hubs</span>
                    </div>
                    <div>
                        <span>PROJECT NAME: </span>
                        <input ref={this.titleRef}></input>
                    </div>
                    <div>
                        <span>CONTRIBUTORS: </span>
                        <input ref={this.contribRef} defaultValue={this.props.username}></input>
                    </div>
                    <button onClick={this.props.closeProjectCreator}>Cancel</button>
                    <button onClick={this.createProject}>Create</button>
                </div>
            </div>
        )
    }
}

export default ProjectCreator;