import React from 'react';
import GroupCue from './GroupCue'

class CueDisplay extends React.Component {
    cuesInGroup(groupName) {
        return this.props.cues.filter(x => x.trig.groupChain === groupName);
    }

    render() {
        return (
            <>
                <div>
                    <h3>Project Groups: {this.props.groups.length}</h3>
                    <h3>Project Cues: {this.props.cues.length}</h3>
                </div>
                {Object.keys(this.props.groups).map(key => <GroupCue key={this.props.groups[key]} groupCues={this.cuesInGroup(this.props.groups[key])} groupName={this.props.groups[key]} showPopup={this.props.showPopup}/>)}
            </>
        )
    }
}

export default CueDisplay;