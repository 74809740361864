import React from 'react';
import {firebase} from '../base'
//import auth from '../base';

class AccountCreator extends React.Component {
    emailInput = React.createRef();
    passInput = React.createRef();
    userInput = React.createRef();

    state = {
        success : false
    }
    
    createLogin = (event) => {
        event.preventDefault();
        let email = this.emailInput.current.value;
        let pass = this.passInput.current.value;
        let username = this.userInput.current.value;
        firebase.auth().createUserWithEmailAndPassword(email, pass)
            .then((userCredential) => {
            // Signed in
            var user = userCredential.user;
            console.log(user);
            user.updateProfile({
                    displayName: username,
                }).then(() => {
                    let success = true;
                    this.setState({success});
                    setTimeout(() => {
                        this.navigate('');
                    }, 1500);
                    // Update successful
                    // ...
                }).catch((error) => {
                    // An error occurred
                    // ...
                }); 
            //update user profile
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    }
    navigate = (valUser) => {
        this.props.history.push(`/${valUser}`);
    }
    render() {
        return (
            <>
            <form className="login-page" onSubmit={this.createLogin}>
                <h1>Join our crew</h1>
                <input type="text" required placeholder="email" ref={this.emailInput}></input>
                <input type="text" required placeholder="password" ref={this.passInput}></input>
                <input type="text" required placeholder="username" ref={this.userInput}></input>
                <button type="submit">Create an account</button>
            </form>
            {this.state.success && (
                <>
                <h1>Success!</h1>
                </>
            )}
            </>
        )
    }
}

export default AccountCreator;