import React from 'react';
import CueType from './CueType';
import { matchEdit } from '../utils/EditorHelpers'

class CueEditor extends React.Component {
    state = {
        stagedCue : this.props.cue
    }
    
    handleSave = () => {
        //FINALIZE ALL EDITS HERE AND SEND UPWARDS
        console.log('cue saved');
        this.props.onCueUpdate(this.state.stagedCue);
    }

    handleEdit = (event) => {
        //STAGE ALL EDITS HERE
        //console.log(event)
        //console.log(event.target.id, event.target.value);
        let stagedCue = matchEdit(event.target.id, event.target.value, this.state.stagedCue);
        this.setState({stagedCue});
        //console.log(newCue);
    }

    handleDelete = (event) => {
        this.props.deleteCue(this.state.stagedCue);
    }

    handleClose = () => {
        //console.log('closing editor');
        this.props.onClosePopup();
    }

    checkSceneLink = () => {
        if (this.state.stagedCue.action._cue) {
            if (this.state.stagedCue.action._cue.sceneLink !== undefined) {
                return this.state.stagedCue.action._cue.sceneLink;
            } else if (this.state.stagedCue.sceneLink !== undefined) {
                return this.state.stagedCue.sceneLink;
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    render() {
        if (!this.state.stagedCue) return;
        return (
            <div className="cue-editor">
                <div className="cue-content">
                    <div>
                        <span>CUE NAME : </span>
                        <input id="name" type="text" required defaultValue={this.state.stagedCue.name} onChange={this.handleEdit}></input>
                    </div>
                    <div>
                        <span>CUE GROUP : </span>
                        <input id="group" type="text" required defaultValue={this.state.stagedCue.trig.groupChain ? this.state.stagedCue.trig.groupChain : ""} onChange={this.handleEdit}></input>
                    </div>
                    <div>
                        <span>ROLE : </span>
                        <input id="role" type="text" required defaultValue={this.state.stagedCue.role} onChange={this.handleEdit}></input>
                    </div>
                    <div>
                        <span>SCENE SCOPE : </span>
                        <input id="sceneScope" type="text" required defaultValue={this.checkSceneLink()} onChange={this.handleEdit}></input>
                    </div>
                    <CueType cue={this.state.stagedCue} handleEdit={this.handleEdit}/>
                    <div>
                        <span>TRIGGER DELAY : </span>
                        <input id="time" type="text" required defaultValue={this.state.stagedCue.trig.time} onChange={this.handleEdit}></input>
                    </div>
                    <div>
                        <button onClick={this.handleClose}>Close Editor</button>
                        <button onClick={this.handleSave}>{this.props.newCue ? "Create Cue" : "Save Cue"}</button>
                        {!this.props.newCue && (
                            <button onClick={this.handleDelete}>Delete Cue</button>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    componentDidMount() {
        //console.log(this.state.stagedCue)
    }
}

export default CueEditor;