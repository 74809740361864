import React from 'react';
import { render } from 'react-dom';
import Router from './components/Router.js';
import './css/style.css'
import cueDB from './cueDB.json';
import store from "./base";
import userDB from './userDB.json';
import newDB from './onboardxrDBNew23.json';

render(<Router />, document.querySelector('#main'));

window.writeDB = () => {
    let cuedb = store.collection('onboardxr-db').doc('cueDB');
    let userdb = store.collection('onboardxr-db').doc('userDB');
    let newdb = store.collection('onboardxr-db-new23').doc('newDB');
    cuedb.set(cueDB)
    .then(() => {
        console.log("cueDB successfully written!");
    })
    .catch((error) => {
        console.error("Error writing document: ", error);
    });
    userdb.set(userDB)
    .then(() => {
        console.log("userDB successfully written!");
    })
    .catch((error) => {
        console.error("Error writing document: ", error);
    });
    newdb.set(newDB)
    .then(() => {
        console.log("newDB successfully written!");
    })
    .catch((error) => {
        console.error("Error writing document: ", error);
    });
}