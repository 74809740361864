import React from 'react';
import TargetRoleInput from "./TargetRoleInput";
import AllowDisInput from './AllowDisInput';

const itemOptions = [{
    name :  "Networked Item",
    value : "glb"
},
{
    name : "Audio File",
    value : "audio"
},
{
    name : "Video File",
    value : "video"
},
{
    name : "Non-Networked Item",
    value : "socket_spawn_object"
},
{
    name: "360 Image",
    value: "load_360_image"
},
{
    name: "360 Gif",
    value: "spawn_360_gif"
}]

class ItemTypeInput extends React.Component {
    state = {
        typeSwitched : false,
        itemType : "",
    }

    reformatItemName = (itemType) => {
        //Used for initial name reformatting
        console.log('reformatting item name', itemType, this.props.cue.target.type)
        if (itemType.includes("spawn_item")) {
            //console.log('spawnItem found')
            if (this.props.cue.target.type === "glb") {
                return "Networked Item";
            } else if (this.props.cue.target.type === "audio") {
                return "Audio File";
            } else if (this.props.cue.target.type === "video") {
                return "Video File";
            }
        } else if (itemType === "socket_spawn_object") {
            return "Non-Networked Item";
        } else if (itemType === "load_360_image") {
            return "360 Image";
        } else if (itemType === "spawn_360_gif") {
            return "360 Gif"
        } else if (itemType === "audio") {
            return "Audio File";
        } else if (itemType === "video") {
            return "Video File";
        } 
        else if (itemType === "glb") {
            return "Networked Item";
        } else {
            return "";
        }
    }

    typeSwitched = (event) => {
        let typeSwitched = {...this.state.typeSwitched};
        typeSwitched = true;
        this.setState({typeSwitched}, ()=>{
            this.props.handleEdit(event);
            this.props.changeItemType(event);
        });

    }

    render() {
        //console.log(this.state.itemType)
        return (
            <div>
                <label>Item Type: </label>
                <select required name="type" id="itemType" onChange={this.typeSwitched}>
                    {this.props.cueType.value && !this.state.typeSwitched && (<option defaultValue={this.props.cueType.value} dangerouslySetInnerHTML={ {__html: this.state.itemType ? this.reformatItemName(this.state.itemType) : ""}}></option>)}
                    {Object.keys(itemOptions).map(key => <option key={key} value={itemOptions[key].value}>{itemOptions[key].name}</option>)}
                </select>
                {this.state.itemType !== "cue_category" && this.state.itemType !== "glb" && this.state.itemType !== "spawn_item" && this.state.itemType !== "audio"  && this.state.itemType !== "video" && (
                    <TargetRoleInput handleEdit={this.props.handleEdit} cue={this.props.cue}/>
                )}
                {this.state.itemType === "spawn_item" || this.state.itemType === "socket_spawn_object" && this.props.cue.target.type === "glb" && (
                    <AllowDisInput handleEdit={this.props.handleEdit}/>
                )}
                {(this.state.itemType === "video" || this.state.itemType === "audio") && (
                    <span>Create comp for media params</span>
                    // create component for params
                )}
            </div>
        )
    }
    componentDidUpdate(prevProp) {
        //console.log(this.props.itemType, this.props.cueType.value)
        //console.log('comp update: ' + this.props.itemType);
        if (prevProp.itemType !== this.props.itemType) {
            if (this.props.itemType) {
                //console.log(itemType);
                let itemType = this.props.itemType;
                this.setState({itemType}, () => {
                    console.log(this.state.itemType)
                });
            }
        }
    }

    componentDidMount() {
        console.log(this.props.cueType.value);
        if (this.props.cueType.value !== "cue_category") {
            let itemType = this.props.itemType;
            this.setState({itemType});
        } else { 
            let itemType = this.props.cueType.value;
            this.setState({itemType});
        }
    }
}

export default ItemTypeInput