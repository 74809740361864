import React from 'react';

class ProjectPreview extends React.Component {
    state = {
        removeReady : false
    }
    
    handleClick = () => {
        //console.log(this.props.navigate);
        this.props.navigate(this.props.projData.title)
    }

    handleRemoveProject = () => {
        if (!this.state.removeReady) {
            let removeReady = true;
            this.setState({removeReady});
            setTimeout(() => {
                let removeReady = false;
                this.setState({removeReady});
            }, 2500);
        } else {
            this.props.removeProject(this.props.projData.title);
        }
    }

    render() {
        const { title, dateCreated, lastEdit, totalCues} = this.props.projData;
        return (
            <div className="project-preview">
                <p>Title: {title}</p>
                <p>Date Created: {dateCreated}</p>
                <p>Last Edited: {lastEdit}</p>
                <p>Total Cues: {totalCues}</p>
                <button onClick={this.handleClick}>Edit Project</button>
                <button onClick={this.handleRemoveProject}>{!this.state.removeReady ? "REMOVE PROJECT" : "ARE YOU SURE?"}</button>
            </div>
        )
    }
}

export default ProjectPreview;