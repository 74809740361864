import React from 'react';

class ChooseNewRig extends React.Component {
    render() {
        return (
            <>
                <button onClick={this.props.createNewProject}>Create New Project</button>
            </>
        )
    }
}

export default ChooseNewRig;