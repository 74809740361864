import React from 'react';

class RefDistanceInput extends React.Component {
    render() {
        return (
            <div>
                <span>Reference Distance: </span>
                <input id="refdist" type="text" defaultValue={this.props.cue.action._cue ? this.props.cue.action._cue.action.refDistance : ""} onChange={this.props.handleEdit}></input>
            </div>
        )
    }
}

export default RefDistanceInput;