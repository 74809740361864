import defaultCues from '../defaultcues.json'

const socketCues = ["manipulate_avatar", "change_avatar", "recall_audience", "jump_to_waypoint", "track_object", "change_rolloff", "change_animation", "handleCueObjectAnim", "play_360_gif", "socket_toggle_flight", "socket_toggle_movement"];

export function matchEdit(id, value, cue) {
    //console.log('matching edit', id, value, cue);
    let path = reformatID(id, value, cue);
    console.log(path, id);
    if (path) {
        if (id === "cueType" || id === "itemType") {
            //RESET TARGET AND ACTION
            cue.target = defaultCues.listDefaults[0].default.target;
            if (path.includes('_cue') && path.includes('timelines')) {
                cue.action = defaultCues.listDefaults[1].default.action;
            } else if (path.includes('_cue') && !path.includes('timelines')) {
                //console.log('no timelines')
                cue.action = defaultCues.listDefaults[2].default.action;
            } else {
                cue.action = defaultCues.listDefaults[0].default.action;
            }
            //ASSIGN EDIT
            cue = assignToPath(cue, path, value);
            //console.log(cue);
        } else if (id === "newtimeline") {
            //add all existing timelines to value
            console.log(cue)
            let timelines;
            if (!cue.action._cue.action.timelines) {
                timelines = [];
            } else {
                timelines = [...cue.action._cue.action.timelines];
            }
            console.log(timelines)
            //timelines = [...cue.action._cue.action.timelines];
            timelines.push(value);
            value = timelines;
            //console.log(timelines, value)
            cue = assignToPath(cue, path, value);
        } else {
            cue = assignToPath(cue, path, value);
        }
    }
    return cue;
    //if path includes "_cue, target, role" reformat into array
}

function assignToPath(cue, path, edit) {
    //console.log(cue, path, edit);
    let propPath = [...path]
    let prop = propPath.pop();
    //console.log(prop);
    let obj = propPath.reduce((prev, curr) => {
        //console.log(prev, curr);
        return prev[curr];
    }, cue);
    //console.log(JSON.stringify(obj))
    if (obj) {
        obj[prop] = edit;
        return cue;
    } else {
        console.log('pathway not found on cue');
        return cue;
    }

    //console.log(obj, cue);
    //console.log(cue);
}
// array1.reduce(
//     (previousValue, currentValue) => previousValue + currentValue,
//     initialValue
//   );
function reformatID(id, value, cue) {
    let path;
    switch(true) {
        case id === "name" :
            path = ["name"];
            break;
        case id === "group" :
            path = ["trig", "groupChain"];
            break;
        case id === "role" :
            path = ["role"];
            break;
        case id === "sceneScope" :
            if (cue.action._cue) {
                path = ["action", "_cue", "sceneLink"]
            } else {
                path = ["sceneLink"];
            };
            break;
        case id === "cueType" :
            if (value === "cue_category") {
                break;
            } else if (socketCues.includes(value)) {
                path = ["action", "_cue", "action", "type"];
            } else {
                path = ["action", "type"];
            }
            break;
        case id === "time" :
            path = ["trig", "time"];
            break;
        case id === "itemType" :
            if (value === "glb" || value === "audio" || value === "video") {
                path = ["target", "type"];
            } else {
                path = ["action", "_cue", "action", "type"];
            }
            break;
        case id === "targetRole" :
            path = ["action", "_cue", "target", "role"];
            break;
        case id === "url" :
            if (cue.action._cue) {
                if (cue.action._cue.action.type === "change_avatar") {
                    path = ["action", "_cue", "action", "link"]
                } else {
                    path = ["action", "_cue", "target", "src"];
                } 
            } else {
                path = ["target", "src"]
            }
            break;
        case id === "position" : 
            path = ["action", "pos"];
            break;
        case id === "rotation" :
            path = ["action", "rot"];
            break;
        case id === "scale" :
            path = ["action", "scale"];
            break;
        case id === "transProp" :
            path = ["action", "_cue", "target", "aspect"];
            break;
        case id === "newtimeline" :
            path = ["action", "_cue", "action", "timelines"];
            break;
        case id.includes("Timeline") : 
            let timeline = id.charAt(id.length - 1);
            console.log(timeline);
            path = ["action", "_cue", "action", "timelines", timeline];
            break;
        case id === "cuename" :
            if (cue.action._cue.action.type === "delete") {
                path = ["action", "_cue", "action", "src"];
            } else {
                path = ["action", "_cue", "action", "mapItem"];
            }
            break;        
        case id === "waypoint" :
            if (cue.action._cue.action.type === "jump_to_waypoint") {
                path = ["action", "_cue", "action", "anchor"];
            } else {
                path = ["action", "_cue", "action", "waypoint"];
            }
            break;
        case id === "maxdist" :
            path = ["action", "_cue", "action", "maxDist"];
            break;
        case id === "rolloff" :
            path = ["action", "_cue", "action", "rolloff"];
            break;
        case id === "refdist" :
            path = ["action", "_cue", "action", "refDistance"];
            break;
        case id === "animname" :
            path = ["action", "_cue", "action", "animName"];
            break;
        case id === "allowdis" :
            if (cue.action.type === "spawn_item") {
                path = ["action", "pauseOnSpawn"];
            } else if (cue.action._cue.action.type === "socket_toggle_movement") {
                path = ["action", "_cue", "action", "movement"];
            } else if (cue.action._cue.action.type === "socket_toggle_flight") {
                path = ["action", "_cue", "action", "flight"];
            } else if (cue.action._cue.action.type === "socket_spawn_object") {
                path = ["action", "_cue", "action", "pauseOnSpawn"];
            }
            break;
        case id === "scenelink" : 
            path = ["action", "link"];
            break;
        case id === "targetname" :
            path = ["action", "_cue", "target", "name"]
            break;
        }
    return path;
}