import React from 'react';

class TargetRoleInput extends React.Component {
    state = {
        targetRole : []
    }

    handleChange = (event) => {
        let targetRole = {...this.state.targetRole};
        //console.log(event.target.value)
        targetRole = event.target.value.split(', ');
        //console.log(event)
        this.setState({targetRole: targetRole});
        //console.log(this.state.targetRole)
        this.props.handleEdit(event);
    }

    render() {
        return (
            <div>
                <span>Target Role: </span>
                <input id="targetRole" type="text" required defaultValue={this.props.cue.action._cue ? this.props.cue.action._cue.target.role : ''} onChange={this.handleChange}></input>
                {this.state.targetRole.includes("individual") && (
                    <div>
                        <span>Name : </span>
                        <input id="targetname" type="text" required defaultValue={this.props.cue.action._cue ? this.props.cue.action._cue.target.name : ""} onChange={this.props.handleEdit}></input>
                    </div>
                )}
            </div>
        )
    }

    componentDidMount() {
        if (this.props.cue.action._cue) {
            let targetRole = []
            //console.log(targetRole)
            if (typeof this.props.cue.action._cue.target.role === "array") {
                targetRole.push.apply(this.props.cue.action._cue.target.role)
                //console.log(targetRole)
            } else {
                targetRole.push(this.props.cue.action._cue.target.role);
                //console.log(targetRole)
            }
            //console.log(targetRole)
            this.setState({targetRole});
            //console.log(this.state.targetRole)
        }
    }
}

export default TargetRoleInput;