import React from 'react';

class BuildFooter extends React.Component {
    render() {
        return (
            <>
                <h3>Project Actions</h3>
                <button onClick={this.props.saveProject}>Save Project</button>
                <button onClick={this.props.navigate}>Back to Projects</button>
            </>
        )
    }
}

export default BuildFooter;