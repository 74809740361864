import React, { useState } from 'react';
import io from "socket.io-client";
import store from "../base";
import iconPNG from "./assets/icon.svg";
import questPNG from "./assets/question.svg";
import NoSleep from 'nosleep.js';

//enable no sleep
const noSleep = new NoSleep();
document.addEventListener('click', function enableNoSleep() {
    //console.log('no sleep enabled');
    document.removeEventListener('click', enableNoSleep, false);
    noSleep.enable();
}, false);

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
            cuedPopup: false,
            cuedPopupURL: "",
            questIcon: false,
            webappconfig: {},
            _cue: {
                name: "webapp_cue",
                target: { 
                    type: "glb",
                    role: "individual", 
                    name: "",
                    dest: "url",
                    src:  "",
                    lifetime: 5000,
                    ips: 5,
                    sceneLink: "",
                    gravity: false},
                action: {
                    aspect: "",
                    type: "webapp_cue",
                    pos: {x: 0, y:0, z:0},
                    rot: {x: 0, y:0, z:0},
                    scale: {x: 1, y:1, z:1},
                    color: "white"
                }
              },
            socketMap :{
                general : {},
                role: {}
            }
        }
    
        // This binding is necessary to make `this` work in the callback
        this.onButton = this.onButton.bind(this);
        this.onPopup = this.onPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
      }
    
    initSocket() {
        //console.log('init socket')
        const roleClient = io("https://onboard-server.herokuapp.com/mobilewebapp", { transports: ["websocket"] });
        const generalClient = io("https://onboard-server.herokuapp.com/general", { transports: ["websocket"] });

        //connect listener
        roleClient.on('connect', () => {
            //console.log('connected to socket server as webapp');
            let socketMap = {...this.state.socketMap}
            socketMap.role = roleClient;
            this.setState({socketMap})
        });

        generalClient.on('connect', () => {
            //console.log('connected to socket server generally');
            let socketMap = {...this.state.socketMap}
            socketMap.general = generalClient;
            this.setState({socketMap})
        })
        
        //get config change
        roleClient.on('getCue', (_cue) => {
            console.log(_cue)
            this.changeData(_cue.action);
        })
        
    }

    changeData(data) {
        console.log(data);
        //update webconfig and _cue state
        let webappconfig = {...this.state.webappconfig};
        if (data.cuedPopup !== undefined) {
            webappconfig.cuedPopup = data.cuedPopup;
            webappconfig.cuedPopupURL = data.cuedPopupURL;
            let cuedPopup = {...this.state.cuedPopup};
            let cuedPopupURL = {...this.state.cuedPopupURL};
            cuedPopup = data.cuedPopup;
            cuedPopupURL = data.cuedPopupURL;
            this.setState({cuedPopupURL, cuedPopup});
        } else {
            webappconfig = data;
            let _cue = {...this.state._cue};
            _cue.target.name = webappconfig.target;
            _cue.target.lifetime = webappconfig.lifetime;
            _cue.target.ips = webappconfig.ips;
            _cue.target.sceneLink = webappconfig.sceneLink;
            _cue.target.gravity = webappconfig.gravity;
            _cue.action.aspect = webappconfig.aspect;
            this.setState({_cue});
        }
        console.log(webappconfig)
        this.setState({webappconfig});

        //update db
        let db = store.collection('onboardxr-db').doc('webappconfig');
        db.set(webappconfig)
        .then(() => {
            console.log("Document successfully written!");
        })
        .catch((error) => {
            console.error("Error writing document: ", error);
        });
    }

    initData() {
        //setup webappconfig
        let db = store.collection('onboardxr-db').doc('webappconfig');
        db.get().then((doc) => {
            if (doc.exists) {
                console.log(doc.data())
                let webappconfig = {...this.state.webappconfig};
                webappconfig = doc.data();
                if (webappconfig.cuedPopup) {
                    let cuedPopup = {...this.state.cuedPopup};
                    cuedPopup = webappconfig.cuedPopup;
                    let cuedPopupURL = {...this.state.cuedPopupURL};
                    cuedPopupURL = webappconfig.cuedPopupURL;
                    this.setState({cuedPopupURL, cuedPopup}, () => {
                        console.log(this.state)
                    });
                }
                let _cue = {...this.state._cue};
                _cue.target.name = webappconfig.target;
                _cue.target.lifetime = webappconfig.lifetime;
                _cue.target.ips = webappconfig.ips;
                _cue.target.sceneLink = webappconfig.sceneLink;
                _cue.target.gravity = webappconfig.gravity;
                _cue.action.aspect = webappconfig.aspect;
                this.setState({webappconfig});
                this.setState({_cue});
                //console.log(this.state)
            } else {
                console.log('cannot find project data');
            }
        }).catch((error) => {
            console.log('error accessing database: ' + error);
        });

        //console.log(process.env.PUBLIC_URL + '/images/question.png')
    }

    onButton(e) {
        //console.log('button clicked', e.target.alt, e);
        let t = new Date().getTime();
        let _cue = {...this.state._cue};
        _cue.target.src = this.state.webappconfig[e.target.alt].asset;
        _cue.target.name = this.state.webappconfig[e.target.alt].target;
        if (_cue.action.aspect === "scaling") {
            //assign transform properties from cue
            _cue.action.scale = this.state.webappconfig[e.target.alt].scale;
        }
        if (_cue.action.aspect === "color") {
            //assign color properties from cue
            _cue.action.color = this.state.webappconfig[e.target.alt].color;
        }
        if (_cue.action.aspect === "item" || _cue.action.aspect === "prop") {
            //assign transform properties from cue
            _cue.action.pos = this.state.webappconfig[e.target.alt].pos;
            _cue.action.rot = this.state.webappconfig[e.target.alt].rot;
            _cue.action.scale = this.state.webappconfig[e.target.alt].scale;
        }
        _cue.name = _cue.name + "_" + t;
        this.state.socketMap.general.emit("sendCue", _cue.target.role, _cue);
        //console.log(_cue)

        //lower limit
        let webappconfig = {...this.state.webappconfig};
        webappconfig[e.target.alt].limit -= 1;
        this.setState({webappconfig});
    }

    onPopup(e) {
        this.setState({popup: !this.state.popup, questIcon: e.target.alt === "iconBut" ? true : false});
    }

    closePopup(e) {
        this.setState({popup: !this.state.popup});
    }

    render() {
        return (
            <>
                <div className="overlayContainer">
                    <div className="overlayDiv">
                        <div className="emptyHeader">
                            <a className="redirectAnchor" href="https://futurestages.github.io/npcmusical">
                                <div className="clickableRedirect"></div>
                            </a>
                        </div>
                        <div className="topTwo">
                            {this.state.webappconfig.button1 && this.state.webappconfig.button1.limit !== 0 && (
                                <img className="webappButton" src={process.env.PUBLIC_URL + `/images/${this.state.webappconfig.button1.icon}`} alt="button1" onClick={this.onButton}></img>
                            )}
                            {this.state.webappconfig.button3 && this.state.webappconfig.button1.limit === 0 && (
                                <img className="hiddenWebappButton" src={process.env.PUBLIC_URL + `/images/invisible.svg`} alt="button1"></img>
                            )}
                            {this.state.webappconfig.button2 && this.state.webappconfig.button2.limit !== 0 && (
                                <img className="webappButton" src={process.env.PUBLIC_URL + `/images/${this.state.webappconfig.button2.icon}`} alt="button2" onClick={this.onButton}></img>
                            )}
                            {this.state.webappconfig.button4 && this.state.webappconfig.button2.limit === 0 && (
                                <img className="hiddenWebappButton" src={process.env.PUBLIC_URL + `/images/invisible.svg`} alt="button2"></img>
                            )}
                        </div>
                        <div className="topTwo">
                            {this.state.webappconfig.button3 && this.state.webappconfig.button3.limit !== 0 && (
                                <img className="webappButton" src={process.env.PUBLIC_URL + `/images/${this.state.webappconfig.button3.icon}`} alt="button3" onClick={this.onButton}></img>
                            )}
                            {this.state.webappconfig.button3 && this.state.webappconfig.button3.limit === 0 && (
                                <img className="hiddenWebappButton" src={process.env.PUBLIC_URL + `/images/invisible.svg`} alt="button3"></img>
                            )}
                            {this.state.webappconfig.button4 && this.state.webappconfig.button4.limit !== 0 && (
                                <img className="webappButton" src={process.env.PUBLIC_URL + `/images/${this.state.webappconfig.button4.icon}`} alt="button4" onClick={this.onButton}></img>
                            )}
                            {this.state.webappconfig.button4 && this.state.webappconfig.button4.limit === 0 && (
                                <img className="hiddenWebappButton" src={process.env.PUBLIC_URL + `/images/invisible.svg`} alt="button4"></img>
                            )}
                        </div>
                        <div className="bottomTwo">
                            <img className="popupButton" src={iconPNG} alt="iconBut" onClick={this.onPopup}></img>
                            <img className="popupButton" src={questPNG} alt="questBut" onClick={this.onPopup}></img>
                        </div>
                    </div>
                </div>
                {this.state.popup && this.state.questIcon && (
                    <div className='popupDiv' id='iconEl' onClick={this.closePopup}>
                    </div>
                )}
                {this.state.popup && !this.state.questIcon && (
                    <div className='popupDiv1' onClick={this.closePopup}>
                    </div>
                )}
                {this.state.cuedPopup && (
                    <div className='cuedPopupDiv'>
                        <img className='cuePopupDivImg' src={this.state.cuedPopupURL + "?a=" + Math.random()}></img>
                    </div>
                )}
            </>
        )
    }

    componentDidMount() {
        this.initSocket();
        this.initData();
    }
}

export default LoginPage;