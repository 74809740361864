import React from 'react';
import cueTypes from '../testcuetypes.json';
import CueTypeParams from './CueTypeParams';

class CueType extends React.Component {
    selectValue = React.createRef();
    
    state = {
        cueType : this.props.cue.action.type,
        typeSwitched : false
    }

    parseCueType = () => {
        let types = [...cueTypes.cueTypes]
        if (this.props.cue.action.type === "call_method_from_object") {
            let found = types.find(element => element.value === this.props.cue.action._cue.action.type);
            if (found) {
                return found;
            } else {
                return this.props.cue.action._cue.action.type;
            }
        } else if (this.props.cue.action.type === "call_global_function") {
            let found = types.find(element => element.value === this.props.cue.action.function_name);
            if (found) {
                return found;
            } else {
                return this.props.cue.action.function_name;
            }
        } else {
            let found = types.find(element => element.value === this.props.cue.action.type);
            if (found) {
                return found;
            } else {
                return this.props.cue.action.type;
            }
        }
    }

    testSubCategories = (type) => {
        //console.log(type);
        var obj = {};
        let types = {...cueTypes};
        types.subCategories.forEach(element => {
            //console.log(element);
            let key = Object.keys(element)[0];
            //console.log(element[key]);
            if (element[key].includes(type)) {
                obj.name = key;
                obj.params = types.cueTypes.find(x => x.name === key).params;
                obj.value = type;
                //console.log(obj);
            }
        });
        return obj;
    }

    componentDidMount() {
        let found = this.parseCueType();
        //console.log(this.props.cue.action)
        if (typeof found === "string") {
            let newfound = this.testSubCategories(found);
            //console.log(newfound)
            let cueType = {...this.state.cueType};
            cueType = newfound;
            this.setState({cueType});
            //console.log(this.state.cueType)
        } else {
            let cueType = {...this.state.cueType};
            cueType = found;
            this.setState({cueType});
        }
    }

    typeSwitch = (event) => {
        let cueType = {...this.state.cueType};
        //console.log(this.selectValue.current.selectedIndex);
        let types = {...cueTypes};
        cueType = types.cueTypes.find(element => element.value === this.selectValue.current.value);
        if (cueType.value === "cue_category") {
            //console.log('found cue cat');
            let cueName = this.selectValue.current.options[this.selectValue.current.selectedIndex].label
            //console.log(cueName)
            cueType = types.cueTypes.find(element => element.name === cueName);
            //console.log(cueType)
        }
        //console.log(cueType);
        this.setState({cueType}, () => {
            let typeSwitched = {...this.state.typeSwitched};
            typeSwitched = true;
            this.setState({typeSwitched});
            this.props.handleEdit(event);
        });
    }

    render() {
        return (
            <div>
                <label>CUE TYPE: </label>
                <select required name="type" id="cueType" ref={this.selectValue} onChange={this.typeSwitch}>
                    {!this.state.typeSwitched && (<option defaultValue={this.props.cue.action.type} dangerouslySetInnerHTML={ {__html: this.state.cueType.name ? this.state.cueType.name : ""}}></option>)}
                    {Object.keys(cueTypes.cueTypes).map(key => <option key={key} value={cueTypes.cueTypes[key].value}>{cueTypes.cueTypes[key].name}</option>)}
                </select>
                {this.state.cueType.name && (<CueTypeParams cueType={this.state.cueType} cue={this.props.cue} handleEdit={this.props.handleEdit}/>)} 
            </div>
        )
    }
}

export default CueType;