import React from 'react';

class SceneLinkInput extends React.Component {
    render() {
        return (
            <div>
                <span>Scene Link: </span>
                <input id="scenelink" type="text" defaultValue={this.props.cue.action.link} onChange={this.props.handleEdit}></input>
            </div>
        )
    }
}

export default SceneLinkInput;