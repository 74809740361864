import React from 'react';

class WaypointNameInput extends React.Component {
    state = {
        waypointName : "",
        anchorType : false
    }

    handleChange = (event) => {
        // event.target.anchor = this.state.anchor;
        if (this.props.cue.action._cue.action.type === "jump_to_waypoint") {
            if (!event.target.value.includes('#')) {
                event.target.value = "#" + event.target.value;
            }
        }
        //console.log(event.target.value)
        let waypointName = {...this.state.waypointName};
        waypointName = event.target.value;
        this.setState({waypointName}, ()=> {
            this.props.handleEdit(event);
        })
    }
    
    render() {
        return (
            <div>
                <span>Waypoint Name: </span>
                <input id="waypoint" type="text" required defaultValue={this.state.waypointName} onChange={this.handleChange}></input>
            </div>
        )
    }

    componentDidMount() {
        let waypointName;
        //console.log(this.props.cue)
        if (!this.props.cue.action._cue) return;
        if (!this.props.cue.action._cue.waypoint) return;
        if (this.props.cue.action._cue.action.type === "jump_to_waypoint") {
            waypointName = this.props.cue.action._cue.action.waypoint;
            this.setState({waypointName});
        } else {
            waypointName = this.props.cue.action._cue.action.anchor;
            waypointName = waypointName.substring(1);
            let anchor = true;
            this.setState({waypointName});
            this.setState({anchor});
        }
    }
}

export default WaypointNameInput;