import React from 'react';

class CueNameInput extends React.Component {
    state = {
        cueName : ""
    }
    
    render() {
        return (
            <div>
                <span>Cue Name: </span>
                <input id="cuename" type="text" ref={this.xRef} required defaultValue={this.state.cueName} onChange={this.props.handleEdit}></input>
            </div>
        )
    }

    componentDidMount() {
        let cueName;
        if (this.props.cue.action._cue) {
            cueName = this.props.cue.action._cue.action.mapItem;
            this.setState({cueName});
        } else {
            cueName = this.props.cue.target.src;
            this.setState({cueName});
        }
    }
}

export default CueNameInput;