import React from 'react';

class AnimeTableInput extends React.Component {
    state = {
        timeline : {},
        number : ""
    }

    updateTimeline = (event) => {
        let timeline = {...this.state.timeline};
        timeline[event.target.id] = event.target.value;
        this.setState({timeline});
        //console.log(this.state.timeline)
        this.props.handleEdit(this.state.timeline, this.state.number);
    }
    
    render() {
        return (
            <div>
                <span>Timeline {parseInt(this.props.number) + 1}</span>
                <>
                    <span>X: </span>
                    <input id="x" defaultValue={this.props.timeline.x} onChange={this.updateTimeline}></input>
                </>
                <>
                    <span>Y: </span>
                    <input id="y" defaultValue={this.props.timeline.y} onChange={this.updateTimeline}></input>
                </>
                <>
                    <span>Z: </span>
                    <input id="z" defaultValue={this.props.timeline.z} onChange={this.updateTimeline}></input>
                </>
                {this.props.timeline.rx !== undefined && (
                    <>
                        <span>RX: </span>
                        <input id="rx" defaultValue={this.props.timeline.rx} onChange={this.updateTimeline}></input>
                    </>
                )}
                {this.props.timeline.ry !== undefined && (
                    <>
                        <span>RY: </span>
                        <input id="ry" defaultValue={this.props.timeline.ry} onChange={this.updateTimeline}></input>
                    </>
                )}
                {this.props.timeline.rz !== undefined && (
                    <>
                        <span>RZ: </span>
                        <input id="rz" defaultValue={this.props.timeline.rz} onChange={this.updateTimeline}></input>
                    </>
                )}
                {this.props.timeline.s !== undefined && (
                    <>
                        <span>S: </span>
                        <input id="s" defaultValue={this.props.timeline.s} onChange={this.updateTimeline}></input>
                    </>
                )}
                {this.props.timeline.easing !== undefined && (
                    <>
                        <span>Easing: </span>
                        <input id="easing" defaultValue={this.props.timeline.easing} onChange={this.updateTimeline}></input>
                    </>
                )}
                {this.props.timeline.duration !== undefined && (
                    <>
                        <span>Duration: </span>
                        <input id="duration" defaultValue={this.props.timeline.duration} onChange={this.updateTimeline}></input>
                    </>
                )}
            </div>
        )
    }

    componentDidMount() {
        let timeline = this.props.timeline;
        let number = this.props.number;
        this.setState({timeline});
        this.setState({number});
    }
}

export default AnimeTableInput