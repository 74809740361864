import React from 'react';
import ItemTypeInput from "./ItemTypeInput";
import UrlInput from "./UrlInput";
import PositionInput from "./PositionInput";
import RotationInput from "./RotationInput";
import ScaleInput from "./ScaleInput";
import TargetRoleInput from "./TargetRoleInput";
import CueNameInput from "./CueNameInput";
import TransformPropertyInput from "./TransformPropertyInput";
import WaypointNameInput from "./WaypointNameInput";
import MaxDistanceInput from "./MaxDistanceInput";
import TrackingParamsInput from "./TrackingParamsInput";
import RolloffInput from "./RolloffInput";
import RefDistanceInput from "./RefDistanceInput";
import AnimNameInput from "./AnimNameInput";
import AllowDisInput from "./AllowDisInput";
import SceneLinkInput from "./SceneLinkInput";

class CueTypeParams extends React.Component {
    state = {
        cueType : this.props.cueType,
        itemType : ""
    }
    
    changeItemType = (event) => {
        //console.log(event.target.value);
        if (!event.target) return
        if (event.target.value === "audio" || event.target.value === "video" || event.target.value === "glb") {
            let cueType = {...this.state.cueType};
            let value = "spawn_item";
            cueType.value = value;
            this.setState({cueType})
        } else {
            let cueType = {...this.state.cueType};
            let value = event.target.value;
            cueType.value = value;
            this.setState({cueType});
        }
        let itemType = event.target.value;
        this.setState({itemType})
    }

    render() {
        //console.log(this.state.cueType)
        if (this.state.cueType.value === "cue_category" && this.state.cueType.name !== "Manipulate Element") {return (<ItemTypeInput cue={this.props.cue} cueType={this.state.cueType} changeItemType={this.changeItemType} handleEdit={this.props.handleEdit}/>)} else {
            return (
                <div>
                    {this.state.cueType && (this.state.cueType.params.includes("ItemTypeInput") && (
                        <ItemTypeInput itemType={this.state.itemType} cue={this.props.cue} changeItemType={this.changeItemType} cueType={this.state.cueType} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("UrlInput") && (
                        <UrlInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("PositionInput") && (
                        <PositionInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("RotationInput") && (
                        <RotationInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("ScaleInput") && (
                        <ScaleInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("TargetRoleInput") && (
                        <TargetRoleInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("CueNameInput") && (
                        <CueNameInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("TransformPropertyInput") && (
                        <TransformPropertyInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("WaypointNameInput") && (
                        <WaypointNameInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("MaxDistanceInput") && (
                        <MaxDistanceInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("TrackingParamsInput") && (
                        <TrackingParamsInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("RolloffInput") && (
                        <RolloffInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("RefDistanceInput") && (
                        <RefDistanceInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("AnimNameInput") && (
                        <AnimNameInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("AllowDisInput") && (
                        <AllowDisInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                    {this.state.cueType && (this.state.cueType.params.includes("SceneLinkInput") && (
                        <SceneLinkInput cue={this.props.cue} handleEdit={this.props.handleEdit}/>
                    ))}
                </div>
            )
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.cueType.value !== prevProps.cueType.value) {
            let cueType = {...this.state.cueType};
            cueType = this.props.cueType;
            this.setState({cueType});
            console.log(this.props.cue)
        }
    }
}

export default CueTypeParams;