import React from 'react';
import AnimeTableInput from './AnimeTableInput'

class TrackingParamsInput extends React.Component {
    state = {
        timelines : []
    }

    timelineUpdate = (timeline, number) => {
        //console.log(timeline)
        let timelines = {...this.state.timelines};
        timelines[number] = timeline;
        this.setState({timelines});
        //console.log(timelines);
        let edit = {
            target : {
                id : "Timeline" + number,
                value : this.state.timelines
            }
        }
        this.props.handleEdit(edit);
    }

    addTimeline = () => {
        let emptyTimeline = {x: "", y: "", z:"", rx:"0", ry:"0", rz:"0", s: "0", easing: "linear", duration: "0", delay: "0"}
        let timelines = {...this.state.timelines};
        console.log(timelines);
        let nextKey = Object.keys(timelines).length;
        timelines[nextKey] = emptyTimeline;
        this.setState({timelines});
    }
    
    render() {
        return (
            <div>
                {Object.keys(this.state.timelines).map(key => <AnimeTableInput key={key} number={key} timeline={this.state.timelines[key]} handleEdit={this.timelineUpdate}/>)}
                <button onClick={this.addTimeline}>Add Timeline</button>
            </div>
        )
    }

    componentDidMount() {
        if (!this.props.cue.action._cue) return;
        let timelines = this.props.cue.action._cue.action.timelines ? this.props.cue.action._cue.action.timelines : [];
        this.setState({timelines});
    }
}

export default TrackingParamsInput;