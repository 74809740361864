import React from 'react';

class AnimNameInput extends React.Component {
    state = {
        animName : undefined
    }
    
    render() {
        return (
            <div>
                <span>Anim Name: </span>
                <input id="animname" type="text" ref={this.xRef} required value={this.state.animName} onChange={this.props.handleEdit}></input>
            </div>
        )
    }

    componentDidMount() {
        if (!this.props.cue.action._cue) return;
        let animName = this.props.cue.action._cue.action.animName;
        this.setState({animName});
    }
}

export default AnimNameInput;