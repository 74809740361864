import React from 'react';

class RolloffInput extends React.Component {
    render() {
        return (
            <div>
                <span>Rolloff: </span>
                <input id="rolloff" type="text" defaultValue={this.props.cue.action._cue ? this.props.cue.action._cue.action.rolloff : ""} onChange={this.props.handleEdit}></input>
            </div>
        )
    }
}

export default RolloffInput;