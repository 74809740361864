import React from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import LoginPage from './LoginPage.js';
import UserPage from './UserPage.js';
import BuilderPage from './BuilderPage.js';
import AccountCreator from './AccountCreator.js';
import MobileWebApp from './MobileWebApp.js'

const Router = () => (
    <BrowserRouter> 
        <Switch>
            <Route exact path="/" component={LoginPage}/>
            <Route exact path="/createAccount" component={AccountCreator}/>
            <Route exact path="/mobilewebapp" component={MobileWebApp}/>
            <Route path="/:username/:project" component={BuilderPage}/>
            <Route path="/:username" component={UserPage}/>
        </Switch>
    </BrowserRouter>
)

export default Router;