import React from 'react';
import AnimeTableInput from './AnimeTableInput'

class TransformPropertyInput extends React.Component {
    typeRef = React.createRef();
    
    state = {
        typeSwitched : false,
        transformType : "",
        timelines : []
    }

    typeSwitch = (event) => {
        let typeSwitched = true;
        this.setState({typeSwitched});
        let transformType = {...this.state.transformType};
        transformType = event.target.value;
        this.setState({transformType}, ()=>{
            let edit = {
                target : {
                    id : "transProp",
                    value : this.state.transformType
                }
            }
            this.props.handleEdit(edit);
        });
        
        //console.log(event.target.value);
    }

    timelineUpdate = (timeline, number) => {
        //console.log(timeline)
        let timelines = {...this.state.timelines};
        timelines[number] = timeline;
        this.setState({timelines});
        //console.log(timelines);
        let edit = {
            target : {
                id : "Timeline" + number,
                value : this.state.timelines
            }
        }
        this.props.handleEdit(edit);
    }

    addTimeline = () => {
        let emptyTimeline = {x: "", y: "", z:"", easing: "linear", duration: "0", delay: "0"}
        let timelines = {...this.state.timelines};
        //console.log(timelines);
        let nextKey = Object.keys(timelines).length;
        timelines[nextKey] = emptyTimeline;
        this.setState({timelines});
        let transformType = {...this.state.transformType};
        transformType = this.typeRef.current.value;
        this.setState({transformType}, ()=>{
            let edit = {
                target : {
                    id : "newtimeline",
                    value : emptyTimeline
                }
            }
            this.props.handleEdit(edit);
        });
    }
    
    render() {
        //console.log(this.state.transformType)
        return (
            <div>
                <span>Transform Property: </span>
                <select required name="type" ref={this.typeRef} id="transformproperty" onChange={this.typeSwitch}>
                    {this.props.cue.action._cue && !this.state.typeSwitched && (<option defaultValue={this.props.cue.action._cue.target ? this.props.cue.action._cue.target.aspect + "Trans" : ""} dangerouslySetInnerHTML={ {__html: this.props.cue.action._cue.target.aspect ? this.props.cue.action._cue.target.aspect : ""}}></option>)}
                    <option value="positionTrans">position</option>
                    <option value="rotationTrans">rotation</option>
                    <option value="scaleTrans">scale</option>
                </select>
                {this.state.transformType !== "" && (
                    Object.keys(this.state.timelines).map(key => <AnimeTableInput key={key} number={key} timeline={this.state.timelines[key]} handleEdit={this.timelineUpdate}/>)
                )}
                <button onClick={this.addTimeline}>Add Timeline</button>
            </div>
        )
    }

    componentDidMount() {
        if (!this.props.cue.action._cue) return;
        let transformType = this.props.cue.action._cue.target.aspect ? this.props.cue.action._cue.target.aspect + "Trans" : "";
        let timelines = this.props.cue.action._cue.action.timelines ? this.props.cue.action._cue.action.timelines : [];
        console.log(this.props.cue);
        this.setState({transformType});
        this.setState({timelines});
        console.log(this.state)
    }
}

export default TransformPropertyInput;