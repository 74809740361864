import React from 'react';

class ProjectsHeader extends React.Component {
    render() {
        return (
            <h3>Projects</h3>
        )
    }
}

export default ProjectsHeader;