import React from 'react';

class AllowDisInput extends React.Component {
    state = {
        cueType : "",
        allowDis : {
            map : true,
            value : true
        }
    }

    handleChange = (event) => {
        let allowDis = {...this.state.allowDis}
        let value = !allowDis.value;
        allowDis.value = value;
        event.target.value = value;
        //console.log(allowDis);
        this.setState({allowDis}, () => {
            //console.log(allowDis)
            this.props.handleEdit(event);
        })
    }
    
    render() {
        return (
            <div>
                <span>{this.state.cueType}</span>
                {this.state.cueType !== undefined && (
                    <input type="checkbox" id="allowdis" value={this.state.allowDis.value} onChange={this.handleChange} checked={this.state.allowDis.value}/>
                )}
            </div>
        )
    }

    componentDidUpdate(prevProps) {
        console.log(prevProps)
        if (prevProps !== this.props) {
            //console.log(this.props)
            let cue = this.props.cue;
            let allowDis = {...this.state.allowDis};
            let cueType;
            if (cue.action._cue) {
                let type = cue.action._cue.action.type;
                if (type === "socket_toggle_movement") {
                    allowDis.value = cue.action._cue.action.movement === "true" || false;
                    cueType = "Disable Movement";
                } else if (type === "socket_toggle_flight") {
                    allowDis.value = cue.action._cue.action.flight === "true" || false;
                    cueType = "Disable Flight";
                }
            } else if (cue.action) {
                if (cue.action.type === "spawn_item") {
                    allowDis.value = cue.action.pauseOnSpawn === "true" || false;
                    cueType = "Pause On Spawn";
                }
            }
            //console.log(allowDis, cueType)
            this.setState({allowDis}, () => {
                this.setState({cueType}, () => {
                    //console.log(cueType)
                });
            });
        }
    }
}

export default AllowDisInput;